import { Directive, ElementRef, inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPasswordInput]',
  standalone: true,
})
export class PasswordInputDirective {
  renderer = inject(Renderer2);
  constructor(private el: ElementRef) {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'class',
      'text-[#E1E1E1] rounded py-3 px-4 w-full text-base flex border border-[#DCDCDC] bg-[#F4F4F480]'
    );
  }
}
