import { createAction, props } from '@ngrx/store';

import { LookupProps, LookupState } from '../reducers/lookup.reducer';

const prefix = '[Lookup]';

export const genderlookupAction = createAction(
  `${prefix} gender`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const stateLookupAction = createAction(
  `${prefix} states`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const lgasLookupAction = createAction(
  `${prefix} lgas`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const industriesLookupAction = createAction(
  `${prefix} industries`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const expertSystemsLookupAction = createAction(
  `${prefix} expert systems`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const revenueHeadsLookupAction = createAction(
  `${prefix} revenue heads`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const paymentChannelsLookupAction = createAction(
  `${prefix} payment channels`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const paymentGatewaysLookupAction = createAction(
  `${prefix} payment gateways`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const mdaLookupAction = createAction(
  `${prefix} MDA`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const taxPayerCategoriesLookupAction = createAction(
  `${prefix} tax payer categories`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);
export const taxOfficesLookupAction = createAction(
  `${prefix} tax offices`,
  props<{ data: LookupProps[]; isLoading: boolean }>()
);

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<LookupState>>()
);
