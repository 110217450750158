import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.get('password') || !control.get('confirmPassword')) {
    return;
  }
  const matches =
    control.get('password').value === control.get('confirmPassword').value;

  if (!control.get('confirmPassword')?.value) {
    control.get('confirmPassword')?.setErrors({ required: true });
    return;
  }

  if (!matches) {
    control.get('confirmPassword')?.setErrors({ matches: true });
    return;
  }
  control.get('confirmPassword')?.setErrors(null);
  return null;
};

//symbol test
// /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
