import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LookupState } from '../reducers/lookup.reducer';

// export const isLoggedIn = createSelector(
//   ((state) => state['auth'],
//   (state) => state['lgas'],
//   ([auth, lga]) => [auth, lga])
// );

const getLookupState = createFeatureSelector<LookupState>('lookup');

export const getLookupStore = createSelector(getLookupState, (state) => {
  return state;
});
export const getGender = createSelector(getLookupState, (state) => {
  return state.gender;
});

export const getStates = createSelector(getLookupState, (state) => {
  return state.states;
});

export const getLgas = createSelector(getLookupState, (state) => {
  return state.lgas;
});

export const getIndustries = createSelector(getLookupState, (state) => {
  return state.industries;
});

export const getExpertSystems = createSelector(getLookupState, (state) => {
  return state.expertSystems;
});

export const getRevenueHeads = createSelector(getLookupState, (state) => {
  return state.revenueHeads;
});

export const getPaymentChannels = createSelector(getLookupState, (state) => {
  return state.paymentChannels;
});

export const getPaymentGateways = createSelector(getLookupState, (state) => {
  return state.paymentGateways;
});

export const getMda = createSelector(getLookupState, (state) => {
  return state.mda;
});

export const getTaxPayerCategories = createSelector(getLookupState, (state) => {
  return state.taxPayerCategories;
});

export const getTaxoffices = createSelector(getLookupState, (state) => {
  return state.taxOffices;
});
