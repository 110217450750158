import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GeneralInputDirective } from '../../../directives/inputs/general-input.directive';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { PasswordInputDirective } from '../../../directives/inputs/password-input.directive';

@Component({
  selector: 'app-password-input',
  standalone: true,
  imports: [
    PasswordInputDirective,
    CommonModule,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.css',
})
export class PasswordInputComponent {
  @Input() label = 'Password';
  //   @Input() type = 'password';
  @Input() placeholder = 'Enter password';
  @Input() controlName = '';
  @Input() isRequired = false;
  @Input() formGroup: FormGroup<any>;

  showPassword = true;

  toggleInputType() {
    this.showPassword = !this.showPassword;
  }

  @Input() get type() {
    return this.showPassword ? 'password' : 'text';
  }
}
