import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const customPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let errors: ValidationErrors = {};

  // If the form hasn't been touched then don't validate
  if (control.pristine) {
    return null;
  }

  const password = (control.get('password')?.value as string) || control?.value;
  const confirmPassword =
    (control.get('confirmPassword')?.value as string) || '';

  const isPasswordAString = typeof password === 'string';
  const isConfirmPasswordAString = typeof confirmPassword === 'string';
  const testUpperCase = /[A-Z]/.test(password as string);
  const testLowerCase = /[a-z]/.test(password as string);
  const testNumber = /[0-9]/.test(password as string);
  const testSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    password as string
  );
  const testLength = !isPasswordAString ? false : password?.trim().length >= 8;

  if (!testUpperCase) {
    errors['upper'] = true;
  }
  if (!testLowerCase) {
    errors['lower'] = true;
  }
  if (!testNumber) {
    errors['numeric'] = true;
  }
  if (!testSymbol) {
    errors['symbol'] = true;
  }
  if (!testLength) {
    errors['length'] = true;
  }
  return errors;
};
