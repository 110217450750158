<div class="flex flex-col w-full gap-2">
  <label
    for=""
    *ngIf="!!label"
    [ngClass]="{
      'text-[#5D5E5E] font-medium text-sm': true,
      'text-red-500': !!isRequired
    }"
    >{{ label }}</label
  >

  <div
    appPasswordInput
    [formGroup]="formGroup"
    [ngClass]="{ '!border-red-500 !border-[2px]': !!isRequired }"
  >
    <input
      [type]="type"
      [placeholder]="placeholder"
      [formControlName]="controlName"
      class="flex-1 outline-none text-[#5D5E5E] min-w-0 text-base bg-[#F4F4F480]"
      autocomplete="new-password"
    />
    <button (click)="toggleInputType()" type="button">
      <span [ngClass]="{ 'pointer-events-none': true }">
        <svg-icon
          *ngIf="showPassword"
          src="assets/svgs/eye_closed.svg"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>
      </span>
      <span [ngClass]="{ 'pointer-events-none': true }">
        <svg-icon
          *ngIf="!showPassword"
          src="assets/svgs/eye_open.svg"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>
      </span>
    </button>
  </div>
</div>
